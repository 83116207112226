/*global $,taxes,method_reply*/
/**
 * Created by eboye on 4/13/16.
 */
$(function () {
    'use strict';
    $(document).ready(function () {
        $('.dropdown-button').dropdown({
            inDuration: 300,
            outDuration: 225,
            constrain_width: false, // Does not change width of dropdown to that of the activator
            hover: true, // Activate on hover
            gutter: 0, // Spacing from edge
            belowOrigin: true, // Displays dropdown below the button
            alignment: 'left' // Displays dropdown with edge aligned to the left of button
        });
        $('.material-select').material_select();
        $('select[required]').css({
            position: 'absolute',
            display: 'inline',
            height: 0,
            padding: 0,
            width: 0,
            top: 0,
            left: 0
        });
        $('#homepageslider').slick({
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: false,
            autoplay: false,
            autoplaySpeed: 2000,
            prevArrow: '<div class="slick-prev slick-arrow" aria-label="Previous"><i class="material-icons">keyboard_arrow_left</i></div>',
            nextArrow: '<div class="slick-next slick-arrow" aria-label="Next"><i class="material-icons">keyboard_arrow_right</i></div>'
        });
        $('a[href="#gototop"]').on('click', function (e) {
            e.preventDefault();
            $('html, body').animate({
                scrollTop: 0
            }, 1000);
        });
        function precise_round(num, decimals) {
            var t = Math.pow(10, decimals);
            return (Math.round((num * t) + (decimals > 0 ? 1 : 0) * (Math.sign(num) * (10 / Math.pow(100, decimals)))) / t).toFixed(decimals);
        }

        function taxCalc() {
            var catID = $('#category').val(),
                qNo = $('#questionno').val(),
                tax,
                price,
                hoursReply,
                totalPrice,
                selectreply,
                long_price_text = $('#long_question_price').find('span.long_price'),
                long_price_input = $('#long_price'),
                totalPriceWrap = $('#total_price').find('.total_price_wrap'),
                questionsElems = $('textarea.question-check'),
                questionsTooLongPrice = 0;
            if (catID && qNo) {
                questionsElems.each(function () {
                    if ($(this).attr('data-priceadd') === 'true') {
                        questionsTooLongPrice += parseFloat($(this).data('pricetoadd'));
                    }
                });
                long_price_text.text(questionsTooLongPrice);
                long_price_input.val(questionsTooLongPrice);
                tax = parseFloat(taxes[catID][qNo].tax);
                price = parseFloat(taxes[catID][qNo].price);
                selectreply = $('input[name=method_reply]:checked', '#hours_radio').attr('data-value').trim();
                hoursReply = parseFloat(selectreply ? selectreply : 0);
                totalPrice = (price + hoursReply + questionsTooLongPrice) * (1 + tax / 100);
                totalPrice = precise_round(totalPrice, 2);
                $('#tax').val(tax).prev().addClass('active');
                totalPriceWrap.removeClass('hide');
                totalPriceWrap.find('.price').text(totalPrice);
                $('#express_reply_price').find('.exp_price').text(hoursReply);
            }
        }

        taxCalc();
        $('#hours_radio').find('input').on('change', function () {
            taxCalc();
        });
        $('#category').closest('.select-wrapper').on('change', function () {
            taxCalc();
        });
        $('#questionno-wrap').on('change', function () {
            taxCalc();
            var questionNo = $('#questionno-wrap').find('input.select-dropdown').val(),
                q1 = $('#q1'),
                q2 = $('#q2'),
                q3 = $('#q3'),
                q4 = $('#q4');
            if (parseInt(questionNo, 10) === 1) {
                q1.removeClass('hide');
                q2.addClass('hide');
                q3.addClass('hide');
                q4.addClass('hide');
            } else if (parseInt(questionNo, 10) === 2) {
                q1.removeClass('hide');
                q2.removeClass('hide');
                q3.addClass('hide');
                q4.addClass('hide');
            } else if (parseInt(questionNo, 10) === 3) {
                q1.removeClass('hide');
                q2.removeClass('hide');
                q3.removeClass('hide');
                q4.addClass('hide');
            } else if (parseInt(questionNo, 10) === 4) {
                q1.removeClass('hide');
                q2.removeClass('hide');
                q3.removeClass('hide');
                q4.removeClass('hide');
            }
        });
        $('input#input_text, textarea#textarea1').characterCounter();
        $('.button-collapse').sideNav();
        $('.bank-btn').on('click', function (e) {
            e.preventDefault();
            var bank_url = $(this).attr('data-url');
            $('.bank-btn').removeClass('btn-flat');
            $(this).siblings().addClass('btn-flat');
            $('#pay-via-bank').attr('href', bank_url).removeClass('disabled');
        });
        function setAdditionalPrice(elem) {
            var length = elem.val().length,
                allowedLength = parseInt(elem.data('allowedprice'));
            if (length > allowedLength) {
                elem.attr('data-priceadd', 'true');
            } else {
                elem.attr('data-priceadd', 'false');
            }
        }

        $('textarea.question-check').on('drop keydown keyup paste change', function () {
            setAdditionalPrice($(this));
            taxCalc();
        });
    });
});
